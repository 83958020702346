import p5 from 'p5';

import { getElementById } from './dom';

let containerId;

const calculateSize = () => {
  let width = 300;
  let height = 300;

  const container = getElementById(containerId);
  if (container) {
    width = container.clientWidth;
    height = container.clientHeight;
  }

  return { width, height };
};

let width;
let height;
let yOffset;

const sketch = (p) => {
  const bgColor = 'rgb(238, 245, 242)';
  const bgColorDraw = 'rgba(238, 245, 242, .04)';
  const strokeColor = 'rgb(68, 210, 178)';

  p.setup = () => {
    ({ width, height } = calculateSize());
    yOffset = 0.0;

    p.createCanvas(width, height);
    p.background(bgColor);
    p.noFill();
    p.frameRate(40);
  };

  p.draw = () => {
    p.background(bgColorDraw);
    p.stroke(strokeColor);
    p.strokeWeight(1);

    let xOffset = 0.0;
    let step = 10;

    p.beginShape();
    for (let x = 0; x < width + step / 2; x += step) {
      let y = p.map(p.noise(xOffset, yOffset), 0, 1, 0, height);
      p.vertex(x, y);
      xOffset += 0.04;
    }
    yOffset += 0.01;
    p.endShape();

    p.windowResized = () => {
      ({ width, height } = calculateSize());
      p.resizeCanvas(width, height);
      p.background(bgColor);
      p.noFill();
    };
  };
};

const start = (cId) => {
  containerId = cId;
  new p5(sketch, containerId);
};

export default { start };
