import graph from './lib/graph.js';
import { getElementById, onDocumentReady } from './lib/dom';

const identityStart = () => {
  if (window.location.protocol === 'https:') {
    const secureElem = getElementById('secure');
    if (secureElem) {
      secureElem.classList.remove('hidden');
    }
  }

  graph.start('graph-container');
};

onDocumentReady(identityStart);
